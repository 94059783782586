import React from 'react';

import { useGlobalState } from '../global/global';

import '../styles/page505.css';
import Layout from '../components/layout';

const Page505 = props => {
    const [state, dispatch] = useGlobalState();

    const setImageSrc = () => {
        if (state.lightThemeState) {
            return '/images/light/505.png'
        }

        if (!state.lightThemeState) {
            return '/images/dark/505.png'
        }
    }

    return (
        <Layout location={props.location}>
        <div className='page505'>
            <img src={setImageSrc()} className='page505__image' alt='505 image' />
        </div>
    </Layout>
    );
}

export default Page505